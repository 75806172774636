import React, { useState } from 'react'
import axios from 'axios'
import './Modal.css'

const LoginModal = ({ isOpen, onClose, onLogin }) => {
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      // Send POST request for magic link
      const response = await axios.post(
        'https://routewatcher.com/users/send-magic-link/',
        { email }
      )

      // Store the username in localStorage
      // const { username } = response.data
      // localStorage.setItem('username', username)
      if (response.data.username) {
        localStorage.setItem('username', response.data.username)
      }

      setSuccessMessage(response.data.status) // Show success message
      setErrorMessage('')
    } catch (error) {
      setSuccessMessage('')
      setErrorMessage(
        error.response?.data?.error || 'An error occurred. Please try again.'
      )
    }
  }

  if (!isOpen) return null

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2>Login with Magic Link</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="submit-button">
            Send Magic Link
          </button>
        </form>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  )
}

export default LoginModal
